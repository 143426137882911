import { useEffect, useState } from "react";
import AdminLayout from "../../components/admin/AdminLayout";
import { useTranslation } from "react-i18next";
import { Button  , Box , InputLabel , Typography , Paper } from "@mui/material";
import { useForm,Controller }   from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading            from "../../components/Loading-small";
import LoadingSendMessage from "../../components/Loading-small";
import QRCode             from "react-qr-code";
//const { Client } = require('whatsapp-web.js');
const qrcode                = require('qrcode-terminal');

const style = { marginBottom: 30 };

export default function WhatsForm() {
  const { phone }             = useParams();
  const [qrcode, setQRCode]   = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const { t }                 = useTranslation();
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhone]     = useState("");
  const { enqueueSnackbar }   = useSnackbar();
  const { token }             = useSelector((state) => state.admin);
  const lang = Cookies.get("i18next") || "en";
  const sendDirect = async () => {
    /*
    setLoading(true);
    setQRCode("");
    const client     = new Client();
     console.log("Client Start Connected to Whats app");
     console.log(message);
     
     client.on('qr', qr => {
       console.log('QR RECEIVED = ', qr);
       qrcode.generate(qr, { small: true });
       setLoadingMsg(true);
       setLoading(false);
     });
     
     
     client.on('ready', () => {
      setLoadingMsg(true);
       console.log('Client is ready! ، I Will Send');
     
       var nomer_telephone = ["+201012591423", "+201096193358","+201020189717"];
       var textId = `Hi, Muscat ` + message; 
       console.log(textId);
       for (var nomor = 0; nomor < nomer_telephone.length; nomor++) {
         var chatId = nomer_telephone[nomor].substring(1) + "@c.us"; 
         client.sendMessage(chatId, textId);
       }
       console.log("END SEND........");
       setLoadingMsg(false);
     });
     
     client.initialize();
     console.log("END ALL Whats appp");
     */
  }

  useEffect(() => {
    //loadingQRCode();
  }, []);

  console.log(phone);
  const getQRCode = async () => {
    setLoading(true);
    setQRCode("");
    const res = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/sendWhatsapp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          phone   : phone,
          message : message,
        }),
      }
    );
    console.log("------------------------");
    console.log("GET QRCode");
    const resData   = await res.json();
    console.log(resData.data);
    setQRCode(resData.data);
    setLoading(false);
  };

  const sendWhatsAppMessage = async () => {
    console.log("Press Whats app");

    const mobileNumber = '01020189717'; // Replace with the recipient's phone number
    const text = encodeURIComponent(message);

    try {
      let number = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");

      // Appending the phone number to the URL
        let url = `https://web.whatsapp.com/send?phone=${number}`;
  
      // Appending the message to the URL by encoding it
        url += `&text=${encodeURI(message)}&app_absent=0`;
  
      // Open our newly created URL in a new tab to send the message
        window.open(url);


      console.log('WhatsApp opened successfully on Android');
    } catch (error) {
      console.error('Error opening WhatsApp on Android:', error);
    }
  };

  async function onSubmit(data){
    console.log("Enter Submit");

    const formData = new FormData();
    console.log(".............");
    console.log(phone);
    console.log(data.messageMail);

    formData.append("phone",        phone);
    formData.append("messageMail",  data.messageMail);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/admin/sendWhatsapp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: formData,
        }
      );
      if (response.status !== 200 && response.status !== 201) {
        throw new Error("failed occured");
      }
      const resData = await response.json();
      enqueueSnackbar(
        lang === "ar" ? resData.msg.arabic : resData.msg.english,
        { variant: "success", autoHideDuration: 8000 }
      );
    } catch (err) {
      console.log(err);
  }
  };

  

  return (
    <AdminLayout>
      <Paper sx={{ width: "100%", padding: "20px" }}>
      <Box sx={{ marginBottom: "26px" }}>
      <InputLabel disabled="true" sx={{ marginBottom: "6px", fontSize: "18px" , fontWeight:"bold" , color:"#000"}}> {t("title_send_mail")} </InputLabel>
      <div>
          <textarea value={message} className="textarea" onChange={(e) => setMessage(e.target.value)} style={{width:"100%", height: "130px !important;"}}></textarea>
          <div style={{display:"none"}}>
            { 
            ( !loading ) ?
              <LoadingSendMessage style={{width:"40px" , height:"33vh" , padding:"0px"}} />
            : ""
            }
          </div>
          <br />
      </div>

      <button onClick={getQRCode} style={{padding:"10px" , border:"1px solid #800000" , color:"#FFF" , backgroundColor:"#800;", background : "#800" , marginTop:"20px" , borderRadius:"10px"}}>الحصول علي QR Code</button>
      { ( loading  ) ? ( //&& qrcode
          <div>
            <Loading />
            <p style={{textAlign:"center" , marginBottom:"10px"}}> ارجوا الانتظار قليلا الي ان يتم ارسال الرسائل </p>
          </div>
       ) :
       (
        <div style={{ margin: "20px" , display : "none"}}>
        <QRCode value={qrcode} style={{display:"none"}} />
        <p>ارجوا الانتظار قليلا الي ان يتم ارسال الرسائل</p>
      </div>

        
       )}

  <div>
    <br />
    <br />
    <br />
      {t("whatsappnumber")} = {phone}
      <input value={phone} onChange={(e) => setPhone(e.target.value)} style={{display:"none"}}/>
      </div>
      <br />
      
    <br />
    <br />
    <br />

        <p><b>ملحوظه :</b> يرجي مسح QR Code لكي يتم التواصل مع حساب واتس اب و لكي يتم من خلاله ارسال الرسائل و ان حدث اي خلل خلال مسح  QR code يرجى حذف الاتصال السابق و اعاده الاتصال مره اخرى  </p>
      </Box>
      </Paper>
    </AdminLayout>
  );
}
